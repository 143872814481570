import React, {useContext, useState} from 'react'
import {MyContext} from './MyContext';

import Logo from '../../components/assets/login/logo.jpg';

import '../../App.css';


function Login(){

    const {toggleNav,loginUser,isLoggedIn} = useContext(MyContext);

    const initialState = {
        userInfo:{
          //  email:'',
            documento:'',
            clave:'',
        },
        errorMsg:'',
        successMsg:'',
    }

    const [state,setState] = useState(initialState);

    // On change input value (email & password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo:{
                ...state.userInfo,
                [e.target.name]:e.target.value
            }
        });
    }

    // On Submit Login From
    const submitForm = async (event) => {
        event.preventDefault();
        const data = await loginUser(state.userInfo);
        if(data.success && data.token){
            setState({
                ...initialState,
            });
            localStorage.setItem('loginToken', data.token);
            await isLoggedIn();
        }
        else{
            setState({
                ...state,
                successMsg:'',
                errorMsg:data.message
            });
        }
    }

    // Show Message on Error or Success
    let successMsg = '';
    let errorMsg = '';
    if(state.errorMsg){
        errorMsg = <div className="error-msg">{state.errorMsg}</div>;
    }
    if(state.successMsg){
        successMsg = <div className="success-msg">{state.successMsg}</div>;
    }

    const [recuperar_pass, setRecuperar_pass] = useState("VOLVER A INICIO");

    const [loading, setLoading] = useState(false);

    function loadData(){
        setLoading(false);

        setTimeout(()=>{
            setLoading(true);
            //alert("No se pudo crear la indicacion")
        }, 100);
    }

    const recuperar_password= (e) => {
        // console.log(e.target.value) // si || no
        setRecuperar_pass(e.target.value)
      }

    return(
<>
<div id="loader" className="app-loader">
</div>


<div id="app" className="app">

<div className="login login-with-news-feed">

<div className="news-feed">
<div className="news-image Fondo" ></div>

</div>


<div className="login-container">

<div className="login-header mb-30px">
<div className="brand">
<div className="d-flex align-items-center">
<img src={Logo} width="250px" />
</div>

</div>
<div className="icon">
<i className="fa fa-sign-in-alt"></i>
</div>
</div>


<div className="login-content">
{recuperar_pass === "VOLVER A INICIO" && (
<form className="fs-13px" onSubmit={submitForm} noValidate>
<div className="form-floating mb-15px">
<input name="documento" type="text" className="form-control h-45px fs-13px" placeholder="Usuario"
 value={state.userInfo.documento} onChange={onChangeValue} autoComplete="off" />
<label type="number" for="emailAddress" className="d-flex align-items-center fs-13px text-gray-600">Documento</label>
</div>
<div className="form-floating mb-15px">
<input name="clave" type="password" className="form-control h-45px fs-13px" placeholder="Password" autoComplete="off"
 value={state.userInfo.clave} onChange={onChangeValue} />
<label for="password" className="d-flex align-items-center fs-13px text-gray-600">Contraseña</label>
</div>


<div className="mb-15px ">
<button type="submit" className="btn btn-info d-block h-45px w-100 btn-lg fs-14px">INICIAR SESIÓN</button>
</div>

<div className='row'>

<div className="mb-5px col-6 ">
<input type="button" value="RECUPERAR CLAVE"  className="btn btn-primary d-block h-35px w-100 btn-lg fs-12px"  onClick={recuperar_password} />
</div>

<div className="mb-5px  col-6">
<button type="submit" className="btn btn-primary d-block h-35px w-100 btn-lg fs-12px"> SOLICITAR DEMO</button>
</div>
</div>

{/*
<div className="mb-40px pb-40px text-dark">
 <a href="#" className="text-primary" style={{textDecoration: 'none'}}>SOLICITAR <b>DEMO</b></a>
</div>
    */}
<div className="mb-40px pb-40px text-dark">
</div>

<center><h4>{errorMsg}</h4></center>
{successMsg}
<hr className="bg-gray-600 opacity-2" />
<div className="text-gray-600 text-center  mb-0">
&copy; Todos los derechos reservados aliraweb.com
</div>
</form>

)}

<div className="col-sm-12">
        {recuperar_pass === "RECUPERAR CLAVE" && (
            <>
            <form className="fs-13px" method="post" action="https://alira.ai-od.com/email/clave_usuarios.php" target="_parent" name="reset" noValidate>
          <div className="form-floating mb-15px">
          <input name="email" type="email" className="form-control h-45px fs-13px" placeholder="Usuario"
            autoComplete="off" />
          <label type="email" for="emailAddress" className="d-flex align-items-center fs-13px text-gray-600">Email</label>
          </div>
         
          <div className="mb-15px">
          <button type="submit" onClick={loadData} disabled={loading} className="btn btn-primary d-block h-45px w-100 btn-lg fs-14px">RECUPERAR CLAVE</button>
          </div>
        

          <div className="mb-40px pb-40px text-dark">
 
 <input
            type="button"
           
            value="VOLVER A INICIO"    
            className="btn btn-default"       
            onClick={recuperar_password}
          /> 
</div> 
          
          
<hr className="bg-gray-600 opacity-2" />
<div className="text-gray-600 text-center  mb-0">
&copy; Todos los derechos reservados aliraweb.com
</div>
          </form>
          </>
          
        )}
      </div>

</div>

</div>

</div>

</div>



<a href="javascript:;" className="btn btn-icon btn-circle btn-success btn-scroll-to-top" data-toggle="scroll-to-top"><i class="fa fa-angle-up"></i></a>


</>
      
    );
}

export default Login;