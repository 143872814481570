import './App.css';
import {HashRouter as Router, Routes, Route, Navigate} from "react-router-dom";

import MyContextProvider from './components/login/MyContext';
import history from './components/login/history';

import Inicio from './components/views/inicio/Inicio';
import Empresas from './components/views/empresas/Empresas';
import Empresas_creadas from './components/views/empresas/Empresas_creadas';
import Empresas_suspendidas from './components/views/empresas/Empresas_suspendidas';
import Licencias from './components/views/licencias/Licencias';
import Solicitudes from './components/views/solicitudes/Solicitudes';
import Firma from './components/views/empresas/Firma';

import Cargo_empresas from './components/views/empresas/Cargo_empresas';
import Cargos from './components/views/empresas_usuarios/Cargos';
import Editar_cargos from './components/views/empresas_usuarios/Editar_cargos';
import Perfil_cargos from './components/views/empresas_usuarios/Perfil_cargos';

import Calcular_costos from './components/views/empresas_usuarios/Calcular_costos';

import Count from './components/views/empresas/Count';

import Alarma_demo from './components/views/empresas/Alarma_demo';


import Crear_empleados from './components/views/empresas_usuarios/Crear_empleados';
import Crear_contratos from './components/views/empresas_usuarios/Crear_contratos';


import Mi_hoja_de_vida from './components/views/empleados/Mi_hoja_de_vida';

import Teletrabajo_suplementario from './components/views/empleados/Teletrabajo_suplementario';

import Hojas_de_vida from './components/views/empresas/Hojas_de_vida';

import List_documentos from './components/views/empresas/List_documentos';

import Pruebas from './components/views/empresas_usuarios/Pruebas';

import Visitas_domiciliarias from './components/views/empresas_usuarios/Visitas_domiciliarias';

import Examenes_ingreso from './components/views/empresas_usuarios/Examenes_ingreso';


import Termino_fijo from './components/views/contratos/Termino_fijo';
import Categoria_contratos from './components/views/contratos/Categoria_contratos';
import A_distancia from './components/views/CTermino_fijo/A_distancia';
import Contrato_a_distancia from './components/views/CTermino_fijo/Contrato_a_distancia';
import Cto_suplementario from './components/views/CTermino_fijo/Cto_suplementario';
import Cto_moviles from './components/views/CTermino_fijo/Cto_moviles';
import Cto_autonomo from './components/views/CTermino_fijo/Cto_autonomo';
import Cto_remoto from './components/views/CTermino_fijo/Cto_remoto';

import Puesto_trabajo from './components/views/CTermino_fijo/Puesto_trabajo';
import Cto_ser_d from './components/views/CTermino_fijo/Cto_ser_d';
import Cto_ninera from './components/views/CTermino_fijo/Cto_ninera';
import Cto_gerente from './components/views/CTermino_fijo/Cto_gerente';
import Cto_contadora from './components/views/CTermino_fijo/Cto_contadora';
import Cto_vigilante from './components/views/CTermino_fijo/Cto_vigilante';

function App() {
  return (
    <MyContextProvider>
    <Router>
     <Inicio/>
     <Routes>
     <Route path="/Empresas" element = {<Empresas/>}></Route>
     <Route path="/Empresas_creadas" element = {<Empresas_creadas/>}></Route>
     <Route path="/Empresas_suspendidas" element = {<Empresas_suspendidas/>}></Route>
     <Route path="/Licencias" element = {<Licencias/>}></Route>
     <Route path="/Solicitudes" element = {<Solicitudes/>}></Route>
     <Route path="/Firma" element = {<Firma/>}></Route>
     <Route path="/Cargo_empresas" element = {<Cargo_empresas/>}></Route>
     <Route path="/Cargos" element = {<Cargos/>}></Route>
     <Route path="/Editar_cargos" element = {<Editar_cargos/>}></Route>
     <Route path="/Perfil_cargos" element = {<Perfil_cargos/>}></Route>
     <Route path="/Calcular_costos" element = {<Calcular_costos/>}></Route>
     <Route path="/Alarma_demo" element = {<Alarma_demo/>}></Route>
     <Route path="/Count" element = {<Count/>}></Route>
     <Route path="/Crear_empleados" element = {<Crear_empleados/>}></Route>
     <Route path="/Crear_contratos" element = {<Crear_contratos/>}></Route>
     <Route path="/Mi_hoja_de_vida" element = {<Mi_hoja_de_vida/>}></Route>
     <Route path="/Teletrabajo_suplementario" element = {<Teletrabajo_suplementario/>}></Route>
     <Route path="/Hojas_de_vida" element = {<Hojas_de_vida/>}></Route>
     <Route path="/List_documentos" element = {<List_documentos/>}></Route>
     <Route path="/Pruebas" element = {<Pruebas/>}></Route>
     <Route path="/Visitas_domiciliarias" element = {<Visitas_domiciliarias/>}></Route>
     <Route path="/Examenes_ingreso" element = {<Examenes_ingreso/>}></Route>

     <Route path="/Termino_fijo" element = {<Termino_fijo/>}></Route>
     <Route path="/Categoria_contratos" element = {<Categoria_contratos/>}></Route>
     <Route path="/A_distancia" element = {<A_distancia/>}></Route>
     <Route path="/Contrato_a_distancia" element = {<Contrato_a_distancia/>}></Route>
     <Route path="/Cto_suplementario" element = {<Cto_suplementario/>}></Route>
     <Route path="/Cto_moviles" element = {<Cto_moviles/>}></Route>
     <Route path="/Cto_autonomo" element = {<Cto_autonomo/>}></Route>
     <Route path="/Cto_remoto" element = {<Cto_remoto/>}></Route>

     <Route path="/Puesto_trabajo" element = {<Puesto_trabajo/>}></Route>
     <Route path="/Cto_ser_d" element = {<Cto_ser_d/>}></Route>
     <Route path="/Cto_ninera" element = {<Cto_ninera/>}></Route>
     <Route path="/Cto_gerente" element = {<Cto_gerente/>}></Route>
     <Route path="/Cto_contadora" element = {<Cto_contadora/>}></Route>
     <Route path="/Cto_vigilante" element = {<Cto_vigilante/>}></Route>
     

     <Route history={history}></Route>
     <Route path="/" element={<Navigate replace to="/Empresas" />}></Route>
     </Routes>
     </Router>
 </MyContextProvider>
  );
}

export default App;
