import React, {useContext,useState,useEffect,forwardRef} from 'react';
import {MyContext} from '../../login/MyContext';
import {Link} from 'react-router-dom';


import { AddBox, ArrowDownward } from "@material-ui/icons";
import MaterialTable from "material-table";
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';
import Login from '../../login/Login';


//import Row from "./Row";

const multiregistros = {
  cargo: ""
};

function Cargos () {
  const {rootState,logoutUser} = useContext(MyContext);
  const {isAuth,theUser,showLogin} = rootState;

 const {toggleNav,registerUser} = useContext(MyContext);
  
  const baseUrlAd="https://alira.ai-od.com/apiAlira/empresas_usuarios/mis_cargos.php"
  const [data, setData]=useState([]);
  const [modalInsertar, setModalInsertar]=useState(false);
  const [modalEditar, setModalEditar]=useState(false);
  const [modalEliminar, setModalEliminar]=useState(false);
  const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
    id:'',
    cargo:''   
  });

  //multiregistros
 const [rows, setRows] = useState([multiregistros]);
  
    const handleOnChange = (index, name, value) => {
      const copyRows = [...rows];
      copyRows[index] = {
        ...copyRows[index],
        [name]: value
      };
      setRows(copyRows);
    };
  
    const handleOnAdd = () => {
      setRows(rows.concat(multiregistros));
    };
  
    const handleOnRemove = index => {
      const copyRows = [...rows];
      copyRows.splice(index, 1);
      setRows(copyRows);
    };





  
  const [idArticulos, setIdArticulos] = useState(-1);

  const handlerCargarArticulos = function (e){
  const opcion = e.target.value;
  console.log(opcion);
  
  setIdArticulos(opcion);

  }

  
  const handleChange=e=>{
    const {name, value}=e.target;
    setFrameworkSeleccionado((prevState)=>({
      ...prevState,
      [name]: value
    }))
   console.log(frameworkSeleccionado);
  }

  const abrirCerrarModalInsertar=()=>{
    setModalInsertar(!modalInsertar);
  }

  const peticionGet =async() =>{
   // await axios.get(baseUrlAd)
   await axios.get(`https://alira.ai-od.com/apiAlira/empresas_usuarios/mis_cargos.php?usuarioE=${global.idprincipal}`)
    .then(response=>{
     setData(response.data);
    }).catch(error=>{
      console.log(error);
    })

  }

  //console.log("miiii id" + theUser.id);

   const peticionPut = async() =>{
    var f = new FormData();
    f.append("cargo", frameworkSeleccionado.cargo);
    f.append("METHOD", "PUT_CARGO");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
         framework.cargo= frameworkSeleccionado.cargo;
       }
     });
     setData(dataNueva);
     abrirCerrarModalEditar();
    }).catch(error=>{
      console.log(error);
    })

  }


  const peticionPost = async() =>{
    var f = new FormData();
    f.append("cargo", frameworkSeleccionado.cargo);
    f.append("usuarioE", theUser.id);
    f.append("METHOD", "POST");
    await axios.post(baseUrlAd,f)
    .then(response=>{
     setData(data.concat(response.data));
     abrirCerrarModalInsertar();
    }).catch(error=>{
      console.log(error);
    })

  }

  const abrirCerrarModalEditar=()=>{
    setModalEditar(!modalEditar);
  }

  const abrirCerrarModalEliminar=()=>{
    setModalEliminar(!modalEliminar);
  }



  const peticionDelete = async() =>{
    var f = new FormData();
    f.append("METHOD", "DELETE_administrador");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     setData(data.filter(framework=>framework.id!==frameworkSeleccionado.id));
     abrirCerrarModalEliminar();
    }).catch(error=>{
      console.log(error);
    })

  }



  const seleccionarFramework=(framework, caso)=>{
    setFrameworkSeleccionado(framework);
    (caso==="Editar")?
    abrirCerrarModalEditar():
    abrirCerrarModalEliminar()


  }

  useEffect(()=>{
    

    const interval = setInterval(() => {
      
       peticionGet();
       
     }, 2000);
 
   return () => setInterval(interval);
 
   },[])

  

  
  const columnas =[
    {
      title:"ID",
      field: "id",
      
    },
    
    {
      title:"C.EMPRESAS",
      field: "cargo"
    },

    {
      title:'ASIGNADO',
      field:'asignado'
    },

      

    
    
  ];


  
    
    if(isAuth && theUser.tipo_usuario =="empresa")
    {
  return (
 
<div id="content" class="app-content">

<ol class="breadcrumb float-xl-end">

<div class="theme-panel">
<a href="javascript:;" data-toggle="theme-panel-expand" class="theme-collapse-btn"><i class="fa fa-cog"></i></a>
<div class="theme-panel-content" data-scrollbar="true" data-height="100%">
<h5>Color Empresarial</h5>

<div class="theme-list">
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-red" data-theme-class="theme-red" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Red">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-pink" data-theme-class="theme-pink" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Pink">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-orange" data-theme-class="theme-orange" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Orange">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-yellow" data-theme-class="theme-yellow" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Yellow">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-lime" data-theme-class="theme-lime" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Lime">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-green" data-theme-class="theme-green" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Green">&nbsp;</a></div>
<div class="theme-list-item active"><a href="javascript:;" class="theme-list-link bg-teal" data-theme-class="" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Default">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-cyan" data-theme-class="theme-cyan" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Cyan">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-blue" data-theme-class="theme-blue" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Blue">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-purple" data-theme-class="theme-purple" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Purple">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-indigo" data-theme-class="theme-indigo" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Indigo">&nbsp;</a></div>
<div class="theme-list-item"><a href="javascript:;" class="theme-list-link bg-black" data-theme-class="theme-gray-600" data-toggle="theme-selector" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-container="body" data-bs-title="Black">&nbsp;</a></div>
</div>

<div class="theme-panel-divider"></div>
<div class="row mt-10px">
<div class="col-8 control-label text-dark fw-bold">
<div>DÍA / NOCHE </div>
<div class="lh-14">

</div>
</div>
<div class="col-4 d-flex">
<div class="form-check form-switch ms-auto mb-0">
<input type="checkbox" class="form-check-input" name="app-theme-dark-mode" id="appThemeDarkMode" value="1" />
<label class="form-check-label" for="appThemeDarkMode">&nbsp;</label>
</div>
</div>
</div>
<div class="theme-panel-divider"></div>

{/*<div class="row mt-10px align-items-center">
<div class="col-8 control-label text-dark fw-bold">Header Fixed</div>
<div class="col-4 d-flex">
<div class="form-check form-switch ms-auto mb-0">
<input type="checkbox" class="form-check-input" name="app-header-fixed" id="appHeaderFixed" value="1" checked />
<label class="form-check-label" for="appHeaderFixed">&nbsp;</label>
</div>
</div>
  </div> */}
<div class="row mt-10px align-items-center">
<div class="col-8 control-label text-dark fw-bold">Color barra superior</div>
<div class="col-4 d-flex">
<div class="form-check form-switch ms-auto mb-0">
<input type="checkbox" class="form-check-input" name="app-header-inverse" id="appHeaderInverse" value="1" />
<label class="form-check-label" for="appHeaderInverse">&nbsp;</label>
</div>
</div>
</div>
{/*<div class="row mt-10px align-items-center">
<div class="col-8 control-label text-dark fw-bold">Espacio menux</div>
<div class="col-4 d-flex">
<div class="form-check form-switch ms-auto mb-0">
<input type="checkbox" class="form-check-input" name="app-sidebar-fixed" id="appSidebarFixed" value="1" checked />
<label class="form-check-label" for="appSidebarFixed">&nbsp;</label>
</div>
</div>
  </div> */}
<div class="row mt-10px align-items-center">
<div class="col-8 control-label text-dark fw-bold">Espacio menu</div>
<div class="col-4 d-flex">
<div class="form-check form-switch ms-auto mb-0">
<input type="checkbox" class="form-check-input" name="app-sidebar-grid" id="appSidebarGrid" value="1" />
<label class="form-check-label" for="appSidebarGrid">&nbsp;</label>
</div>
</div>
</div>
<div class="row mt-10px align-items-center">
<div class="col-md-8 control-label text-dark fw-bold">Color menu</div>
<div class="col-md-4 d-flex">
<div class="form-check form-switch ms-auto mb-0">
<input type="checkbox" class="form-check-input" name="app-gradient-enabled" id="appGradientEnabled" value="1" />
<label class="form-check-label" for="appGradientEnabled">&nbsp;</label>
</div>
</div>
</div>


</div>
</div>



</ol>


<div class="row">




</div>



<div class="row">

<div class="col-xl-12">


<div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">

<div class="panel-heading ui-sortable-handle">
<h4 class="panel-title">{theUser.name}</h4>
<div class="panel-heading-btn">
<a href="javascript:;" class="btn btn-xs btn-icon btn-default" data-toggle="panel-expand" title="Expandir"><i class="fa fa-expand"></i></a>

</div>
</div>


<div class="panel-body">



<MaterialTable

     
columns={columnas}
data={data}
title= {<><button className="btn btn-success" onClick={()=>abrirCerrarModalInsertar()}> <i class="fas fa-hands-helping fa-2x"></i> AGREGAR CARGO</button></> }
backIconButtonText='Página anterior'
pagination
fixedHeader

actions={[

  /*  {
        icon : 'edit',
        tooltip: 'Editar',
        onClick: (event, framework)=>seleccionarFramework(framework, "Editar")
      },
    
  {
   icon : 'delete',
   tooltip: 'Eliminar',
    onClick: (event, framework)=>seleccionarFramework(framework, "Eliminar")
 }*/
]}
/*options={{
  actionsColumnIndex: -1
}}*/
options={{
    
    actionsColumnIndex: -1,
 actionsCellStyle: {
   // backgroundColor: "#ffccdd",
   // color: "#FF00dd"
  
 },

 headerStyle: { backgroundColor: "#348fe2",  headerStyle: { position: 'sticky'}, boxShadow:"0 0  0 2px rgb(255,255,255)", color: "white", padding: "0.75rem 0.9375rem", fontSize: 10}
}}
localization={{
    header:{
    actions: 'ACCIONES'
    
  },
  body:{ 
    emptyDataSourceMessage:'No hay registros que mostrar',
    
   },

 

   pagination: {
     firstAriaLabel: 'Primera página',
     firstTooltip: 'Primera página',
     labelDisplayedRows: '{from}-{to} de {count}',
     labelRowsPerPage: 'Filas por página:',
     labelRowsSelect: 'filas',
     lastAriaLabel: 'Ultima página',
     lastTooltip: 'Ultima página',
     nextAriaLabel: 'Pagina siguiente',
     nextTooltip: 'Pagina siguiente',
     previousAriaLabel: 'Pagina anterior',
     previousTooltip: 'Pagina anterior',
   },
   toolbar: {
     searchPlaceholder: 'Buscar',
     searchTooltip: 'Buscar',
     showColumnsAriaLabel: 'Mostrar columnas',
     showColumnsTitle: 'Mostrar columnas',
   },

   
}}



/>


<form >
    
</form>
</div>






</div>

</div>




</div>


<Modal isOpen={modalEliminar}>
 <ModalBody>
   ¿Estas seguro que desea eliminar el cargo {frameworkSeleccionado && frameworkSeleccionado.cargo}?
   </ModalBody>
   <ModalFooter>
     <button className="btn btn-danger"
     onClick={()=>peticionDelete()}
     >
       SI
     </button>
     <button
       className="btn btn-secondary"
       onClick={()=>abrirCerrarModalEliminar()}
       >
         NO
     </button>

   </ModalFooter>
 

</Modal>





<Modal isOpen={modalEditar}>
 <ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">EDITAR CARGO</font></ModalHeader>
 <ModalBody>
   <div className="form-group">
   <div className="row">

   <div class="col-12">

<label>Nombre Del Cargo:</label>
<br/>
      <input type="text" className="form-control"  name="cargo" onChange={handleChange}
      value={frameworkSeleccionado && frameworkSeleccionado.cargo}  />
  </div>

  
   
    

   </div>
   </div>
 </ModalBody>
 <ModalFooter>
   <button className="btn btn-primary " onClick={()=>peticionPut()}><i class="fa-solid fa-pen-to-square"></i> Editar</button>{" "}
   <button className="btn btn-danger" onClick={()=>abrirCerrarModalEditar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>
 </ModalFooter>
</Modal>


<Modal isOpen={modalInsertar}>
<ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">CARGO</font></ModalHeader>
 <ModalBody>
   <div className="form-group">
   <div className="row">

<div class="col-12">

<label>Nombre Del Cargo:</label>
<br/>
      <input type="text" className="form-control"  name="cargo" onChange={handleChange}
        />
  </div>

{/*
  <div style={{display:"none"}}>
  <input type="text" className="form-control"  name="tipoUsuario" onChange={handleChange} 
  value={theUser.id}  />
  </div>

*/}
  
 

  </div>

   
   </div>
 </ModalBody>
 <ModalFooter>
   <button className="btn btn-primary" onClick={()=>peticionPost()}><i class="fa-solid fa-pen-to-square"></i> Guardar</button>{" "}
   <button className="btn btn-danger" onClick={()=>abrirCerrarModalInsertar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>
 </ModalFooter>
</Modal>



</div>
   
  )
}

else if(showLogin){
    return"";
}

}

export default Cargos
